import { routes as applicationRoutes } from "@quest-finance/quest-fe-shared/dist/application";
import { routes as authRoutes } from "@quest-finance/quest-fe-shared/dist/auth";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";
import { routes as iamRoutes } from "@quest-finance/quest-fe-shared/dist/iam/";
import { routes as userRoutes } from "@quest-finance/quest-fe-shared/dist/user/";
import { routes as accreditationRoutes } from "../accreditation";
import { routes as staticContentRoutes } from "../static-content";

const privateRoutes: PageRoute[] = [
  ...applicationRoutes,
  ...userRoutes,
  ...staticContentRoutes,
  ...iamRoutes,
];
const publicRoutes: PageRoute[] = [...accreditationRoutes, ...authRoutes];

export { privateRoutes, publicRoutes };
